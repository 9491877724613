import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='text-justify sm:text-left'>
      <div className="container pt-20">
   

        <div >
          <div >
          <br /> <br />
            <h2 class="para-body2" >My Library Privacy Policy </h2>
          </div>
          <div>
            <ul class="para-body1">
              <li >Effective Date: 2025 March 03</li>
              <li >Last Updated Date: 2025 March 03</li>
            </ul>
          </div>

          <br /> <br />
          <h2 class="para-body2" > 1. Information We Collect</h2>
          <div>
            <ul class="para-body1">
              <li >When you use My Library, we may collect the following types of information:</li>
              <li >(a) Personal Information</li>
              <li >* Name, email address, and contact details when you register an account (if applicable).</li>
              <li >* Payment information (only if you make in-app purchases, processed securely by third-party payment providers).</li>
            </ul>
            
          </div>
         
          <div>
            <ul class="para-body1">
              <li >(b) Non-Personal Information</li>
              <li >* Device type, operating system, and app usage statistics.</li>
              <li >* Browsing and reading activity, including book selections and audio playback.</li>
              <li >* IP address and general location data (not precise GPS tracking).</li>
            </ul>
            
          </div>
          <div>
            <ul class="para-body1">
              <li >(c) Cookies & Tracking Technologies</li>
              <li >* We may use cookies and similar technologies to enhance your experience, store preferences, and analyze app performance.You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</li>
            </ul>    
          </div>

          <div>
            <ul class="para-body1">
              <li >(d) Log Data</li>   
              <li >We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</li>       
            </ul>
            
          </div>
          <br />
          <h2 class="para-body2" >2. How We Use Your Information</h2>
          <div>
            <ul class="para-body1">
              <li >We use the collected information to:</li>
              <li >Provide access to e-books, audiobooks, and digital magazines.</li>
              <li >Improve app performance and personalize content recommendations.</li>
              <li >Send you updates, notifications, and marketing materials (you can opt out anytime).</li>
              <li >Prevent fraud, ensure security, and comply with legal obligations.</li>

            </ul>
            <br />  
          </div>
          <h2 class="para-body2" >3. How We Share Your Information</h2>
          <div>
            <ul class="para-body1">
              <li >We do not sell your personal data. However, we may share data in the following cases:</li>
              <li >* Service Providers: With third-party vendors who help operate the app (e.g., cloud storage, analytics, customer support).</li>
              <li >* Legal Compliance: If required by law or to protect rights, safety, or prevent fraud.</li>
              <li >* Business Transfers: In case of a merger, acquisition, or sale of assets, your data may be transferred.</li>
              
            </ul>
            <br /> 
          </div>
          <h2 class="para-body2" >4. Links to Other Sites</h2>
          <div>
            <ul class="para-body1">
            <li > This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</li> 
             
            </ul>
            
          </div>
          <br />
          <h2 class="para-body2" >5. Data Security</h2>
          <div>
            <ul class="para-body1">
              <li >We implement industry-standard security measures to protect your data. However, no system is 100% secure, so we encourage you to use strong passwords and update your app regularly.</li>    
            
            </ul>      
          </div>
          <br />
          <h2 class="para-body2" >6. Your Rights & Choices</h2>
          <div>
            <ul class="para-body1">
              <li >Depending on your location, you may have rights to:</li>
              <li >* Access, update, or delete your personal information.</li>
              <li >* Opt-out of marketing emails.</li>
              <li >* Restrict data processing or object to certain uses.</li>
              <li >To exercise your rights, contact us at mylibrary.digital.lk@gmail.com</li>     
            </ul>
            
          </div>

          <br />
          <h2 class="para-body2" >7. Data Retention Policy</h2>
          <div>
            <ul class="para-body1">
              <li >The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at mylibrary.digital.lk@gmail.com and they will respond in a reasonable time.</li>  
            </ul>  
          </div>

          <br />
          <h2 class="para-body2" >8. Children’s Privacy</h2>
          <div>
            <ul class="para-body1">
              <li >My Library is not intended for children under 13. We do not knowingly collect data from minors. If you believe a child has provided information, please contact us.
              The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.
              My Library does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discover that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (mylibrary.digital.lk@gmail.com) so that they will be able to take the necessary actions.
                
                </li> 
            </ul>     
          </div>
          <br />
          <h2 class="para-body2" >9. Third-Party Links & Services</h2>
          <div>
            <ul class="para-body1">
              <li >The app may contain links to third-party websites or services. We are not responsible for their privacy practices, and we encourage you to review their policies.:</li>
            </ul>
        </div>
        <br />
        <h2 class="para-body2" >10. Compliance with Google Play Policies</h2>
          <div>
            <ul class="para-body1">
              <li >This Privacy Policy is designed to comply with Google Play's User Data Policy. We ensure that:</li>
              <li >* Data collection and usage are transparent and limited to the stated purposes.</li>
              <li >* Users can request data deletion via mylibrary.digital.lk@gmail.com</li>
              <li >* The app does not share sensitive user data without explicit consent.</li>
            </ul>
        </div>
        <br />
        <h2 class="para-body2" >11. Changes to This Policy</h2>
          <div>
            <ul class="para-body1">
              <li >We may update this Privacy Policy periodically. If significant changes are made, we will notify you through the app or other means. Continued use of My Library after changes indicates your acceptance of the updated policy.</li>
            </ul>
        </div>
        <br />
        <h2 class="para-body2" >12. Contact Us</h2>
          <div>
            <ul class="para-body1">
              <li >For questions or concerns about this Privacy Policy, contact us at:</li>
              <li >Email: mylibrary.digital.lk@gmail.com</li>
              <li >Whatsapp: +94 72 076 4777</li>
            </ul>
        </div>
        <br />  <br />
          <div>    
            <ul class="para-body1">
            <li >  <h2 > Your Consent</h2></li>
              <li >By using My Library, you acknowledge that you have read and understood this Privacy Policy and agree to its terms now and as amended by us.</li>
            </ul>          
          </div>
          <br /> <br />
        </div>
      </div>
    </div>
  );
};


export default PrivacyPolicy;

